// Used to highlight some parts of the breadcrumbs when scrolling down
class ScrollHighlight {
  constructor(el) {
    this.el = el;
    this.scroll_top = el.data("scroll-top");
    $(document).on('scroll', () => this.scrollToggle());
  }

  scrollToggle() {
    const highlight = $(document).scrollTop() >= this.scroll_top;
    this.el.toggleClass("highlighted", highlight);
  }
}

export default ScrollHighlight;
