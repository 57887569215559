// Makes sure the value of the selected icon in the Icon Selection popup
// is copied into the hidden input field of the actual form
class IconManage {
  constructor(el) {
    this.el = el;
    $('#icon_select_modal #submit-icon').on('click', this.updatePreview.bind(this));
  }

  updatePreview() {
    const new_value = $('.tab-content .tab-pane.active .menu-icon.active input')[0].value;
    const new_url = $('.tab-content .tab-pane.active .menu-icon.active img').attr('src');
    this.el.removeClass("icon-manage--new");
    this.el.addClass("icon-manage--preview");
    $('.icon-manage-value').val(new_value);
    this.el.css('background-image', `url('${new_url}')`);
  }
}

export default IconManage;
