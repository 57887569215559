/* eslint no-console:0 */
/* eslint max-lines:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript/src and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// This file contains the JS things that is loaded on every page. There may be
// room for improvements if JS load time becomes an issue.

// Too tricky to fix sorting here and keep everything working..
/*eslint sort-imports: "off"*/
import 'balance-text';
import 'selectize';
import '../src/selectize_clear_selection.js';
import '../src/vue/event_app_search.js';
import * as Sentry from '@sentry/browser';
import {BrowserTracing} from "@sentry/tracing";
import {ContentLinkSearch, SubmitButton} from '../src/content_link_search.js';
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require("../src/styles/application.scss");
import AccessGroupConfig from '../src/access_group_config';
import AccessGroupFilter from '../src/access_group_filter';
import AccessGroupForm from '../src/access_group_form';
import AjaxFlashes from '../src/ajax_flashes';
import AppConfig from '../src/app_config';
import { createPopper } from '@popperjs/core';
import BasicTooltip from '../src/basic_tooltip';
import BulkAction from '../src/bulk_action';
import CheckboxToggle from '../src/checkbox_toggle';
import ClipboardJS from 'clipboard';
import Colorpicker from 'vanilla-picker';
import ComconImportToggle from '../src/comcon_import_toggle';
import DatatableSearch from '../src/datatable/search';
import DatatableSelect from '../src/datatable/select';
import DatatableTable from '../src/datatable/table';
import IconManage from '../src/icon_manage';
import ImageUploadManage from '../src/image_upload_manage';
import MenuConfigurator from '../src/menu_configurator';
import MenuImport from '../src/menu_import';
import ModalBulkAction from '../src/modal_bulk_action';
import NetworkingSignups from '../src/networking_signups';
import PreviewSession from '../src/session';
import PromotionPopup from '../src/promotion_popup';
import RadioCheckbox from '../src/radio_checkbox';
import RemoteAutomationForm from '../src/remote_automation_form';
import RemoteFilterForm from '../src/remote_filter_form';
import RemoteFilterSearch from '../src/remote_filter_search';
import RemotePaginate from '../src/remote_paginate';
import RemoteRefresh from '../src/remote_refresh';
import RemoteSorting from '../src/remote_sorting';
import ScrollHighlight from '../src/scroll_highlight';
import SelectionNestedListItems from '../src/selection_nested_list_items';
import TaskUpdater from '../src/task_updater';
import ValidateForm from '../src/validate_form';
import flatpickr from 'flatpickr';

var currentEnv = function() {
  if (window.location.href.indexOf("cms-stg") > -1) {
    return 'staging';
  }
  if (window.location.href.indexOf("megi.event.gd") > -1) {
    return 'production';
  }
  return '';
};

if(currentEnv() === 'staging' || currentEnv() === 'production') {
  Sentry.init({
    dsn: 'https://96d9f923b1ed40f395ad2977b4d0880b@sentry.esanum.de/22',
    integrations: [new BrowserTracing()],
    environment: currentEnv()
  });
}

var initDateTimepickers = function() {
  // Set start of week to Monday
  flatpickr.l10ns.default.firstDayOfWeek = 1;

  // General datetimepicker
  $('.datetimepicker').each(function() {
    flatpickr(this, {
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true
    });
  });

  // Analytics reporting range date picker
  $('.analytics_datepicker').each(function() {
    flatpickr(this, {
      maxDate: 'today',
      dateFormat: 'Y-m-d',
      time_24hr: true
    });
  });
};

var initTooltips = function() {
  // MoreTabItemSection removal tooltip
  const mti_remove_section_tooltip = document.querySelector('.mti-section .remove-section.tooltip');
  const mti_remove_section_button = document.querySelector('.mti-section .mti-section__remove');
  if(mti_remove_section_button && mti_remove_section_tooltip) {
    new BasicTooltip(mti_remove_section_button, mti_remove_section_tooltip).init();
  }

  // Merged branches tooltip
  const merged_branches_tooltip = document.querySelector('footer .merged-branches.tooltip');
  const merged_branches_button = document.querySelector('footer .badge.show-merged-branches');
  if(merged_branches_button && merged_branches_tooltip) {
    new BasicTooltip(merged_branches_button, merged_branches_tooltip).init();
  }

  // Form hint tooltips
  document.querySelectorAll('.hint.tooltip').forEach((tooltip) => {
    const form_hint_icon = tooltip.parentNode.querySelector('i.hint-icon');
    new BasicTooltip(form_hint_icon, tooltip, { placement: 'left' }).init();
  });

  // EventAppSearch hint tooltip
  const search_hint_tooltip = document.querySelector('.event_app_selector-content .search-hint.tooltip');
  const search_hint_button = document.querySelector('.event_app_selector-content .hint-icon');
  if(search_hint_button && search_hint_tooltip) {
    new BasicTooltip(search_hint_button, search_hint_tooltip).init();
  }

  // AccessGroup description hint tooltip
  document.querySelectorAll('.access-group-description.tooltip').forEach((tooltip) => {
    const hint_icon = tooltip.parentNode.querySelector('.access-group-hint-icon');
    new BasicTooltip(hint_icon, tooltip, { placement: 'left' }).init();
  });
};

$(document).ready(() => {
  var selectize_options = {
    hideSelected: true,
    openOnFocus: true
  };

  // Various selectize fields
  $("#assign-users").selectize(selectize_options);
  $("#networking-access-group-attendees-select").selectize(selectize_options);
  $("#networking-access-group-events-select").selectize(selectize_options);
  $(".networking-access-group-select").selectize({});
  $("#cms_user_event_ids").selectize(selectize_options);
  $('#assign_organizer_email').selectize(selectize_options);
  $("#booth_location-booth-select").selectize(selectize_options);
  $('#assign_organizer_email').selectize(selectize_options);

  $("#delegation_email_selectize").selectize({
    create: true,
    load: function(query, callback) { // eslint-disable-line consistent-return
      if (!query.length) return callback();
    }
  });

  // Used for List selection
  $(".item-list-select").selectize(selectize_options);

  $('#brand-select select').selectize({
    onChange: function() {
      this.$control.closest('form').submit();
    }
  });

  $('.datatable-select-filter').selectize({
    allowEmptyOption: true,
    sortField: 'text',
    plugins: {
      'clear_selection': {}
    }
  });

  $(".btn-group").find('input:checked').parent('.btn').addClass('active');

  // Menu configrator
  $('#meglink_search').on('ajax:success', function(){
    $('#meglink_search_modal').modal('hide');
  });

  document.querySelectorAll('.do-radio-checkbox').forEach((el) => {
    new RadioCheckbox($(el));
  });

  const bulk_actions = Array.from(document.querySelectorAll('.do-bulk-action')).map((el) => new BulkAction($(el)));
  const modal_bulk_actions = Array.from(document.querySelectorAll('.do-modal-bulk-action')).map((el) => new ModalBulkAction($(el)));
  const remote_refreshes = Array.from(document.querySelectorAll('.do-remote-refresh')).map((el) => new RemoteRefresh($(el)));

  const datatable_el = document.querySelector('.do-datatable');
  const datatable = new DatatableTable($(datatable_el), bulk_actions, modal_bulk_actions, remote_refreshes);

  document.querySelectorAll('.do-datatable-search').forEach((el) => {
    new DatatableSearch($(el), datatable_el);
  });

  document.querySelectorAll('.do-datatable-select').forEach((el) => {
    new DatatableSelect($(el));
  });

  $('[data-toggle=bulk-modal]').on('click', function(e) {
    e.preventDefault();
    if ($(this).hasClass('disabled')) {
      return;
    }
    const target = $(this).data('target');
    $(target).modal('show');
  });


  document.querySelectorAll('.do-remote-sorting').forEach((el) => {
    new RemoteSorting(el);
  });

  document.querySelectorAll('.do-remote-filter').forEach((el) => {
    new RemoteFilterForm($(el), remote_refreshes, datatable);
  });

  document.querySelectorAll('.do-remote-filter-search').forEach((el) => {
    new RemoteFilterSearch($(el), remote_refreshes, datatable);
  });

  document.querySelectorAll('.do-selection-nested-list-items').forEach((el) => {
    new SelectionNestedListItems($(el));
  });

  document.querySelectorAll('.do-access-group-filter').forEach((el) => {
    new AccessGroupFilter($(el));
  });

  document.querySelectorAll('#content_link_search_modal').forEach((el) => {
    new ContentLinkSearch($(el));
    new SubmitButton($(el), $('.submit-choice', el));
  });

  document.querySelectorAll('.do-scroll-highlight').forEach((el) => {
    new ScrollHighlight($(el));
  });

  $('[data-promotion]').each(function() {
    new PromotionPopup($(this));
  });

  document.querySelectorAll('.do-icon-manage').forEach((el) => {
    new IconManage($(el));
  });

  document.querySelectorAll('.import-from-event').forEach((el) => {
    new MenuImport($(el));
  });

  document.querySelectorAll('.configs.show').forEach(() => {
    new NetworkingSignups();
  });

  document.querySelectorAll('form div.color').forEach((el) => {
    const color_select = el.querySelector('a.color-select');
    const color_input = el.querySelector('input.color-input');
    new Colorpicker({
      parent: color_select,
      alpha: false,
      popup: 'top',
      onDone: (color) => {
        color_input.value = color.hex.substring(0, color.hex.length - 2);
      }
    });
  });

  document.querySelectorAll('.do-validate-form').forEach((el) => {
    new ValidateForm(el);
  });

  document.querySelectorAll('.do-remote-pagination').forEach((el) => {
    new RemotePaginate($(el));
  });

  document.querySelectorAll('[data-disable-on-select]').forEach((el) => {
    new CheckboxToggle(el);
  });

  document.querySelectorAll('div.session_preview_session input').forEach((el) => {
    new PreviewSession(el);
  });

  document.querySelectorAll('.do-image-manage').forEach((el) => {
    new ImageUploadManage(el);
  });

  initTooltips();
  var clip = new ClipboardJS('.btn.btn-clipboard');
  clip.on('success', function(e) {
    e.clearSelection();
    const button = document.querySelector('.btn.btn-clipboard');
    const tooltip = button.parentNode.querySelector('.tooltip');
    createPopper(button, tooltip);

    tooltip.setAttribute('data-show', '');
    setTimeout(function () {
      tooltip.removeAttribute('data-show');
    }, 3000);
  });

  initDateTimepickers();

  AccessGroupForm.init();
  AccessGroupForm.toggleRestrictionInputs();

  new AjaxFlashes();
  new MenuConfigurator();
  const menu_configurator = new MenuConfigurator(bulk_actions);
  menu_configurator.init();
  const task_updater = new TaskUpdater();
  task_updater.init();

  // Update the task datatables every 5 seconds if there are progress bars
  if ($('#task_bases-table').length) {
    setInterval(function() {
      var data_table = $('#task_bases-table');
      if (data_table.find('.progress > .progress-bar').length) {
        data_table.DataTable().ajax.reload();
      }
    }, 5000);
  }

  // General Settings stuff
  const app_config = $('#new_app_config');
  const event_config = $('#new_event_config');
  const access_group_config = $('#new_access_group_config');
  const comcon_import_toggle = $('#toggle-recurring-comcon-import');

  if (comcon_import_toggle.length > 0) {
    new ComconImportToggle(comcon_import_toggle);
  }

  if (event_config.length > 0) {
    new RemoteAutomationForm(event_config);
  }

  if (app_config.length > 0) {
    new RemoteAutomationForm(app_config);
    new AppConfig(app_config);
  }

  if (access_group_config.length > 0) {
    new RemoteAutomationForm(access_group_config);
    new AccessGroupConfig(access_group_config);
  }

  // Toggle assign organizer message
  $('#assign_organizer_invitee_message_language_english').click(function(){
    var message = $("#assign_organizer_message").data("message-en");
    $("#assign_organizer_message").text(message);
  });

  $('#assign_organizer_invitee_message_language_german').click(function(){
    var message = $("#assign_organizer_message").data("message-de");
    $("#assign_organizer_message").text(message);
  });

  // Toggle the buttons in the icon selection popup
  // (NOTE: move to webpacker when bootstrap is moved there)
  $('.menu-icon').on('click', function() {
    $(this).button('toggle');
  });

  $(document).on('cocoon:after-insert', function(e, content){
    $('[data-toggle="tooltip"]', content).tooltip();
  });
});
