class MenuImport {
  constructor(el) {
    this.el = el;
    const inputs = $(".item-list-select", el);
    inputs.on('change', this.onChange.bind(this));
    this.onChange();
  }

  onChange() {
    if(this.el.find('.item').data('value') === undefined) {
      this.disableButton();
    } else {
      this.enableButton();
    }
  }

  disableButton() {
    const button = this.el.find('button');
    button.prop('disabled', true);
    button.removeClass('list-group-item-success');
  }

  enableButton() {
    const button = this.el.find('button');
    button.prop('disabled', false);
    button.addClass('list-group-item-success');
  }
}

export default MenuImport;
