class AccessGroupForm {
  // Various setup calls for the cocoon stuff
  static init() {
    $("#access_groups a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');

    $('#access_groups').on('cocoon:after-insert', function() {
      $(".access-group-fields a.add_fields").data("association-insertion-position", 'before').data("association-insertion-node", 'this');
      $('.access-group-fields').on('cocoon:after-insert', function() {
        $(this).children(".access_group_from_list").remove();
        $(this).children("a.add_fields").hide();
      });
      AccessGroupForm.registerAccessRestrictionCallbacks();
    });

    AccessGroupForm.registerAccessRestrictionCallbacks();
  }

  static registerAccessRestrictionCallbacks() {
    $('#access_groups .btn-group .btn').on('change', function() {
      AccessGroupForm.toggleRestrictionInputs();
    });

    // Check if the content link finder modal should be opened or not.
    //
    // It should only be opened if there are any restricted access groups.
    $('.access-restrictions a.btn').on('click', function() {
      if (AccessGroupForm.anyRestricted()) {
        return true;
      }
      return false;
    });
  }

  // Returns true if any access group assignment is marked as 'Restricted'
  static anyRestricted() {
    return $('#access_groups .btn-group input[value=restricted]').toArray().some(function(input) {
      return input.checked;
    });
  }

  // Enable or disable the access group restriction inputs
  static toggleRestrictionInputs() {
    // Toggle the disabled state of all access restriction inputs
    if (AccessGroupForm.anyRestricted()) {
      $('.access-restrictions :input, .access-restrictions a.btn').removeAttr('disabled');
    } else {
      $('.access-restrictions :input, .access-restrictions a.btn').attr('disabled', 'disabled');
    }
  }
}

export default AccessGroupForm;
