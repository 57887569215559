class RemoteFilterForm {
  constructor(el, remote_refreshes, datatable) {
    this.el = el;
    this.remote_refreshes = remote_refreshes;
    this.datatable = datatable;
    this.target = $(this.el.data('target'));
    $("input, select", this.el).on("change", this.applyFilter.bind(this));
  }

  applyFilter() {
    const data = {};
    this.el.serializeArray().forEach(function(f) {
      data[f.name] = f.value;
    });
    this.remote_refreshes.forEach((remote_refresh) => {
      remote_refresh.updateFilters(data);
    });
    this.datatable.updateFilters(data);
  }
}

export default RemoteFilterForm;
