// Validates certain properties of the menu configuration
class MenuValidator {
  constructor() {
    this.shortcuts_minimum_size = 2;

    this.less_than_error = $('.publish-error.less_than');
    this.broken_items_error = $('.publish-error.broken_items');

    this.size = $('.jqtree').tree('getTree').children.length;
    this.apply_button = $('button.apply');
    this.broken_items = $('.item_broken');
  }

  toggle_less_than_error() {
    if(this.menu_type() === 'left_menu' || this.menu_type() === 'app_menu') {
      return;
    }
    if(this.menu_too_small()) {
      this.less_than_error.css('display', 'block');
    } else {
      this.less_than_error.css('display', 'none');
    }
  }

  toggle_broken_items_error() {
    if(this.broken_items.length === 0) {
      this.broken_items_error.css('display', 'none');
    } else {
      this.broken_items_error.css('display', 'block');
    }
  }

  toggle_apply_button() {
    this.apply_button.prop('disabled', !this.valid());
  }

  validate() {
    this.toggle_less_than_error();
    this.toggle_broken_items_error();
    this.toggle_apply_button();
  }

  valid() {
    return !this.menu_too_small() && this.broken_items.length === 0;
  }

  menu_too_small() {
    if(this.menu_type() === 'left_menu' || this.menu_type() === 'app_menu') {
      return false;
    }
    return this.size < this.shortcuts_minimum_size;
  }

  menu_type() {
    const path_parts = location.pathname.split('/');
    return path_parts[path_parts.length - 1];
  }
}

export default MenuValidator;
