import 'cocoon-js-vanilla';
import 'selectize';
import {each, isEmpty} from 'underscore';
import $ from 'jquery';

// This adds support for adding MoreTabItems/Pages to lists
class SelectionNestedListItems {
  constructor(el) {
    this.el = el;
    this.target = $(this.el.data('target'));
    this.template = $(this.el.data('template')).text().trim();
    this.target.selectize({
      sortField: 'value',
      onChange: this.onSelect.bind(this)
    });
    this.options = $.extend(true, {}, this.target.get(0).selectize.options);
    this.el[0].addEventListener('cocoon:after-remove', this.onUpdate.bind(this));
    this.onUpdate();
  }

  onSelect(id) {
    var selection = this.options[id];
    if (selection === null) {
      return;
    }
    var item = this.newItem();
    item.find('.more-tab-item__label').html(selection.item_type + ":\n<strong>" + selection.text + "</strong>");
    item.find('[name$="[item_id]"]').val(selection.value);
    item.appendTo(this.el);
    this.target.val('');
    this.onReorder();
    this.onUpdate();
  }

  onUpdate() {
    var selectize = this.target.get(0).selectize;
    selectize.clearOptions();
    const item_ids = $('li:visible input[name$="[item_id]"]', this.el).map(function(i, input) {
      return input.value;
    }).toArray();
    each(this.options, function(value, id) {
      if (item_ids.indexOf(id) < 0) {
        return selectize.addOption(value);
      } else {
        return null;
      }
    });
    selectize.refreshOptions(false);
    if (isEmpty(selectize.options)) {
      return selectize.disable();
    } else {
      return selectize.enable();
    }
  }

  onReorder() {
    return $('li:visible input[name$="[sort_order]"]', this.el).each(function(i, field) {
      return $(field).val(i);
    });
  }

  newItem() {
    const content = this.template.replace(/new_list_items/g, new Date().getTime());
    return $(content);
  }
}

export default SelectionNestedListItems;
