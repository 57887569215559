import $ from 'jquery';
import TaskPanel from '../src/task_panel.js';

// Handles the UI updates within a task #show view.
class TaskUpdater {
  init() {
    if ($('body.tasks.show').length) {
      const report_panel = new TaskPanel(document.querySelector('.task_report'), {});
      const log_panel = new TaskPanel(document.querySelector('.task_log'), {});

      if ($('.event-import').length) {
        this.import_importing_step = new TaskPanel(document.querySelector('.event-import.importing'),
          {
            'to_collapse': [document.querySelector('.event-import.importing')]
          }
        );
        this.import_complete_step = new TaskPanel(document.querySelector('.event-import.complete'),
          {
            'to_collapse': [document.querySelector('.event-import.importing')]
          }
        );
      }
      if ($('.analytics-update').length) {
        this.analytics_updating_step = new TaskPanel(document.querySelector('.analytics-update.running'), {});
        this.analytics_complete_step = new TaskPanel(document.querySelector('.analytics-update.complete'),
          {
            'to_collapse': [document.querySelector('.analytics-update.running')]
          }
        );
      }
      if ($('.attendee-import').length) {
        this.attendee_importing_step = new TaskPanel(document.querySelector('.attendee-import.importing'), {});
        this.attendee_complete_step = new TaskPanel(document.querySelector('.attendee-import.complete'),
          {
            'to_collapse': [document.querySelector('.attendee-import.importing')]
          }
        );
      }
      if ($('.content-update').length) {
        this.content_updating_step = new TaskPanel(document.querySelector('.content-update.updating'), {});
        this.content_update_complete_step = new TaskPanel(document.querySelector('.content-update.complete'),
          {
            'to_collapse': [document.querySelector('.content-update.updating')]
          }
        );
      }
      if ($('.esanum-access-token-refresh').length) {
        this.updating_step = new TaskPanel(document.querySelector('.esanum-access-token-refresh.updating'), {});
        this.update_complete_step = new TaskPanel(document.querySelector('.esanum-access-token-refresh.complete'),
          {
            'to_collapse': [document.querySelector('.esanum-access-token-refresh.updating')]
          }
        );
      }
      setTimeout(this.update.bind(this), 1000);
    }
  }

  update() {
    $.ajax({
      url: window.location.pathname,
      dataType: "json",
      success: function(data, textStatus, jqXHR) { /* eslint no-unused-vars:0 */
        var status, timer; /* eslint init-declarations:0 */
        this.trigger_panels(data.basic_type, data.step);
        this.update_values(data);
        status = data.status;
        if (status === "Waiting" || status === "Running") {
          return timer = setTimeout(this.update.bind(this), 2000); /* eslint no-return-assign:0 */
        }
        return clearTimeout(timer);
      }.bind(this)
    });
  }

  trigger_panels(basic_type, step) {
    if (basic_type === "event_import") {
      switch (step) {
        case "importing":
          return this.import_importing_step.invoke();
        case "complete":
          return this.import_complete_step.invoke();
        default:
          throw new Error(`${step} is not a valid task step`);
      }
    } else if (basic_type === "analytics_update") {
      switch (step) {
        case "updating":
          return this.analytics_updating_step.invoke();
        case "complete":
          return this.analytics_complete_step.invoke();
        default:
          throw new Error(`${step} is not a valid task step`);
      }
    } else if (basic_type === "attendee_import") {
      switch (step) {
        case "importing":
          return this.attendee_importing_step.invoke();
        case "complete":
          return this.attendee_complete_step.invoke();
        default:
          throw new Error(`${step} is not a valid task step`);
      }
    } else if (basic_type === "content_update") {
      switch (step) {
        case "updating":
          return this.content_updating_step.invoke();
        case "complete":
          return this.content_update_complete_step.invoke();
        default:
          throw new Error(`${step} is not a valid task step`);
      }
    }
    return null;
  }

  // The following 5 methods are specific to the task progress panel only
  update_values(task) {
    const root = $('body.tasks.show');
    this.update_panel_status(root, task);
    this.update_icon(root, task);
    this.update_runtime(root, task);
    this.update_progress(root, task);
  }

  update_panel_status(root, task) {
    const status = root.find('.panel.task_state');
    status.removeClass('panel-success panel-info panel-danger');
    status.addClass(task.status_class);
  }

  update_icon(root, task) {
    const icon = root.find('i.status-icon');

    if (task.status === 'Finished') {
      icon.removeClass('fa-spinner fa-spin');
      icon.addClass('fa-check');
    } else if (task.status === 'Error') {
      icon.removeClass('fa-spinner fa-spin');
      icon.addClass('fa-times');
    }
  }

  update_runtime(root, task) {
    const duration = root.find('.runtime .duration');
    duration.text(`Running for: ${task.duration}`);
  }

  update_progress(root, task) {
    const progress = root.find('.progress-bar');
    progress.css('width', `${task.progress}%`);
  }
}

export default TaskUpdater;
