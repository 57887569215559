import $ from 'jquery';

// This object holds the session date and times
class Session {
  constructor(fields) {
    const start_time = fields.filter('div.session_start_time select').map((i, el) => el.value);
    this.start_time_hours = start_time[0];
    this.start_time_minutes = start_time[1];

    const end_time = fields.filter('div.session_end_time select').map((i, el) => el.value);
    this.end_time_hours = end_time[0];
    this.end_time_minutes = end_time[1];

    this.day = fields.filter('div.session_day select')[0].value;
  }
}

// When a user marks a Session as a preview session, we need to disable the
// time and date input fields. And the other way around.
// This class handles exactly that.
class PreviewSession {
  constructor(el) {
    $(el).on('change', this.onChange.bind(this));
    this.toggle_fields = $('div.session_day select, div.session_start_time select, div.session_end_time select');
    this.old_session = new Session(this.toggle_fields);
  }

  onChange(e) {
    const checkbox = e.target;
    if (checkbox.checked) {
      this.unsetData(this.toggle_fields);
      this.disableFields();
    } else {
      this.setData();
      this.enableFields();
    }
  }

  enableFields() {
    this.toggle_fields.each(function() {
      this.disabled = false;
    });
  }

  disableFields() {
    this.toggle_fields.each(function() {
      this.disabled = true;
    });
  }

  unsetData() {
    this.toggle_fields.each(function() {
      this.value = '';
    });
  }

  setData() {
    const day_field = this.toggle_fields.filter('div.session_day select')[0];
    day_field.value = this.old_session.day;

    const start_time_hours = this.toggle_fields.filter('div.session_start_time select')[0];
    start_time_hours.value = this.old_session.start_time_hours;
    const start_time_minutes = this.toggle_fields.filter('div.session_start_time select')[1];
    start_time_minutes.value = this.old_session.start_time_minutes;

    const end_time_hours = this.toggle_fields.filter('div.session_end_time select')[0];
    end_time_hours.value = this.old_session.end_time_hours;
    const end_time_minutes = this.toggle_fields.filter('div.session_end_time select')[1];
    end_time_minutes.value = this.old_session.end_time_minutes;
  }
}

export default PreviewSession;
