class DatatableSelect {
  constructor(element1) {
    this.element = element1;
    this.element[0].addEventListener('click', this.toggleRows.bind(this));
    this.target = $(this.element.data('target'));
  }

  toggleRows(e) {
    e.preventDefault();
    this.element.blur();
    const rows = $("tbody tr", this.target);
    return rows.each((function(_this) {
      return function(index, element) {
        var row = $(element);
        if (_this.shouldToggle(row)) {
          return row.trigger('click');
        }
        return null;
      };
    }(this)));
  }

  shouldToggle(row) {
    switch (this.element.data('action')) {
      case 'select':
        return !row.hasClass("selected");
      case 'deselect':
        return row.hasClass("selected");
      default:
        throw new Error(`Unexpected case: ${this.element.data('action')}`);
    }
  }
}

export default DatatableSelect;
