// Allows previewing and clearing image upload fields
class ImageUploadManage {
  constructor(el) {
    this.el = $(el);
    this.input = $('input[type=file]', this.el);
    this.input.on('change', this.updatePreview.bind(this));
    this.clear_link = $(".image-manage__clear", this.el);
    this.clear_link.on("click", this.clearImage.bind(this));
    this.clear_field = $(".image-manage__clear-field", this.el);
  }

  clearImage(e) {
    e.preventDefault();
    this.clear_field.val(1);
    this.el.removeClass("image-manage--preview").addClass("image-manage--new");
    this.el.css("background-image", "");
    return $(".image-manage__filename", this.el).remove();
  }

  updatePreview() {
    this.clear_field.val(0);
    const file = this.input[0].files[0];
    if (file === null) {
      return;
    }
    this.el.removeClass("image-manage--new image-manage--preview");
    if (/image\/(png|gif|jpe?g|svg)/i.test(file.type)) {
      this.el.addClass("image-manage--preview");
      const reader = new FileReader();
      reader.onloadend = (function(_this) {
        return function() {
          return _this.el.css('background-image', "url(" + reader.result + ")");
        };
      }(this));
      reader.readAsDataURL(file);
    } else {
      throw new Error(`Unsupported file type ${file.type}`);
    }
  }
}

export default ImageUploadManage;
