// The filter in the access group assigner
class AccessGroupFilter {
  constructor(el) {
    this.search = $("input.access-group-search", el);
    this.items = $(".access-groups__check-box", el);
    this.search.on("keyup", this.toggleItems.bind(this));
  }

  toggleItems() {
    const query = this.search.val().toLowerCase();
    this.items.each(() => {
      const label = $("label", this).text().toLowerCase();
      const visible = label.indexOf(query) > -1;
      $(this).toggle(visible);
    });
  }
}

export default AccessGroupFilter;
