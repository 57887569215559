class RemoteAutomationForm {
  constructor(form) {
    this.form = form;
    this.submit_button = $(".btn-primary.apply", this.form);
    this.cancel_button = $(".btn.form-cancel", this.form);
    this.form[0].addEventListener('ajax:before', this.disableButtons.bind(this));
    this.form[0].addEventListener('ajax:success', this.onSuccess.bind(this));
    this.form[0].addEventListener('ajax:error', this.onError.bind(this));
  }

  enableButtons() {
    this.submit_button.html(this.submit_button.data('original-text')).prop("disabled", false);
    return this.cancel_button.toggleClass("disabled", false);
  }

  disableButtons() {
    const running_message = this.submit_button.data('running-message');
    this.submit_button.data('original-text', this.submit_button.html());
    this.submit_button.prop("disabled", true).html("<i class=\"fa fa-spinner fa-pulse\"></i> " + running_message);
    return this.cancel_button.toggleClass("disabled", true);
  }

  onError(_event) {
    return this.enableButtons();
  }

  onSuccess(event) {
    const data = event.detail[0];
    this.automation_process_ids = data.automation_ids;
    const button_state = this.submit_button.data("state");
    if (button_state === "waiting" || button_state === "running") {
      this.interval_id = setInterval(this.checkState.bind(this), 1000);
      return this.interval_id;
    }
    return null;
  }

  checkState() {
    if (!this.automation_process_ids) {
      console.log('`this.automation_process_ids` is empty');
      return;
    }
    console.log(this.automation_process_ids);
    $.getJSON("/admin/automation_processes/" + this.automation_process_ids, this.updateState.bind(this));
  }

  updateState(data) {
    if (data.state === "success") {
      console.log('Publishing these automations:');
      console.log(this.automation_process_ids);
      this.automation_process_ids.forEach(function(id, _i) {
        return $.post("/admin/automation_processes/" + id + "/publish");
      });
    }
    if (data.state === "error" || data.state === "success") {
      this.enableButtons();
      clearInterval(this.interval_id);
      this.automation_process_ids = [];
      return this.automation_process_ids;
    }
    return null;
  }
}

export default RemoteAutomationForm;
