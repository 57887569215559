class AjaxFlashes {
  constructor() {
    document.body.addEventListener('ajax:complete', function(event) {
      var request = event.detail[0];
      var msg = request.getResponseHeader("X-Message");
      var type = request.getResponseHeader("X-Message-Type");

      if( msg === null || msg === '' || type === null || type === '' ) return;
      this.showAjaxMessage(msg, type);
    }.bind(this));
  }

  showAjaxMessage(msg, type) {
    $("#flash-messages").html('<div class="alert-bar"><div class="alert alert-'+type+'"><button type="button" class="close" data-dismiss="alert">&times;</button>'+msg+'</div></div>');
  }
}

export default AjaxFlashes;
