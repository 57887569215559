import {throttle} from 'underscore';

class RemoteFilterSearch {
  constructor(el, remote_refreshes, datatable) {
    this.el = el;
    this.remote_refreshes = remote_refreshes;
    this.datatable = datatable;
    this.target = $(this.el.data("target"));
    const on_key = throttle(this.sendQuery.bind(this), 200);
    this.el.on("keyup", on_key);
  }

  sendQuery() {
    const data = {q: this.el.val()};
    this.remote_refreshes.forEach((remote_refresh) => {
      remote_refresh.updateFilters(data);
    });
    this.datatable.updateFilters(data);
  }
}

export default RemoteFilterSearch;
