import {debounce, groupBy, map, reduce, sortBy} from 'underscore';
import $ from 'jquery';
import Vue from 'vue/dist/vue.esm';

var selectionMixin = {
  props: {
    currentSelection: Number
  },
  methods: {
    isActive: function(index) {
      return index === this.currentSelection;
    },
    mouseEnter: function(e) {
      e.currentTarget.classList.add('active');
    },
    mouseLeave: function(e) {
      e.currentTarget.classList.remove('active');
    }
  }
};

Vue.component('event-row', {
  mixins: [selectionMixin],
  template: '#event-row',
  props: {
    event: {
      type: Object,
      required: true
    },
    currentEventId: String
  }
});

Vue.component('app-box', {
  mixins: [selectionMixin],
  template: '#app-box',
  props: {
    app: {
      type: Object,
      required: false,
      default: function() {
        return [{app: {}}];
      }
    },
    currentAppId: String
  }
});

if(document.getElementById('event_app_search')) {
  new Vue({
    el: '#event_app_search',
    data: {
      visible: false,
      searchQuery: '',
      currentAppId: null,
      currentEventId: null,
      currentSelection: 0,
      itemCount: 0,
      apps: []
    },
    watch: {
      visible: function() {
        if(this.visible) {
          Vue.nextTick(function() {
            this.$refs.searchValue.focus();
          }.bind(this));
        }
      },
      searchQuery: function() {
        debounce(this.fetchData.bind(this), 200)();
      }
    },
    methods: {
      up: function() {
        if(this.currentSelection > 0) {
          this.currentSelection -= 1;
        }
      },
      down: function() {
        if(this.itemCount && this.currentSelection < this.itemCount - 1) {
          this.currentSelection += 1;
        }
      },
      handleSubmit: function() {
        if(document.querySelector('.app-row a.active')) {
          location.href = document.querySelector('.app-row a.active').href;
          this.visible = false;
        }
      },
      toggleVisibility: function() {
        this.visible = !this.visible;
      },
      fetchData: function() {
        $.ajax({
          context: this,
          url: '/event_app_searches.json',
          data: {
            q: this.searchQuery,
            current_app_id: this.currentAppId,
            current_event_id: this.currentEventId
          },
          success: function(events) {
            this.apps = this.groupedByApp(events, this.currentAppId);

            var event_count = reduce(this.apps, function(sum, app) {
              if(app.events && app.events.constructor === Array) {
                return sum + app.events.length;
              }
              throw new TypeError('`app.events` must be an Array');
            }, 0);
            this.itemCount = this.apps.length + event_count;
            this.limitCurrentSelection();
          }.bind(this)
        });
      },
      groupedByApp: function(events, currentAppId) {
        if(events.length === 0) {
          return [];
        }

        var groupedHash = groupBy(events, function(event) {
          if(event && event.app) {
            return event.app.id;
          }
          throw new TypeError('`event.app` must be set');
        });

        var grouped = map(groupedHash, function(events_for_app) {
          if(events_for_app[0] === undefined || events_for_app[0].app === undefined) {
            return {};
          }
          var app = events_for_app[0].app;
          app.events = events_for_app;
          return app;
        });

        var currentAppOnTop = sortBy(grouped, function(app) {
          return app.id === currentAppId ? 0 : 1;
        });
        return this.insertSelectionIndicies(currentAppOnTop);
      },
      insertSelectionIndicies: function(data) {
        var selectionIndex = 0;
        data.forEach(function(app) {
          app.selectionIndex = selectionIndex;
          selectionIndex += 1;
          app.events.forEach(function(event) {
            event.selectionIndex = selectionIndex;
            selectionIndex += 1;
          });
        });
        return data;
      },
      limitCurrentSelection: function() {
        if(this.currentSelection > this.itemCount) {
          this.currentSelection = this.itemCount - 1;
        }
      },
      setAppEvent: function() {
        if(document.querySelector('#current_app_id')) {
          this.currentAppId = document.querySelector('#current_app_id').dataset.value;
        }
        if(document.querySelector('#current_event_id')) {
          this.currentEventId = document.querySelector('#current_event_id').dataset.value;
        }
      },
      registerEscape: function() {
        window.addEventListener('keyup', function(e) {
          if (e.code === 'Escape') {
            this.visible = false;
          }
        }.bind(this));
      }
    },
    mounted: function() {
      this.setAppEvent();
      this.fetchData();
      this.registerEscape();
    }
  });
}
