import $ from 'jquery';
// Usage Example:
//
// input_html: { data: { disable_on_select: '.app_config_open_registration', enable_on_select: '.personalization_options' } }
//
// You can disable whole fieldsets or only single elements with this.
class CheckboxToggle {
  constructor(el) {
    this.el = $(el);
    this.disable_target = $(this.el.data('disable-on-select'));
    this.enable_target = $(this.el.data('enable-on-select'));
    this.el.on('click', this.onClick.bind(this));
    this.onClick().bind(this);
  }

  onClick() {
    var selected = this.el.is(":checked");
    this.toggleTargets(this.disable_target, selected);
    this.toggleTargets(this.enable_target, !selected);
  }


  toggleTargets(targets, disabled) {
    $('input,select,textarea', targets).prop('disabled', disabled);
    if(disabled) {
      $('input[type=checkbox]', targets).prop('checked', false);
    }
    $('label', targets).toggleClass('text-muted', disabled);
  }
}

export default CheckboxToggle;
