import {debounce} from 'underscore';

// Fetches the search results from the backend and inserts them together with
// a basic HTML template.
class ContentLinkSearch {
  constructor(el) {
    $('#content_link_search_searchtext', el).on('keyup', debounce(this.updateResults, 200));
    $('.btn-group', el).on('click', debounce(this.updateResults, 200));
    this.updateResults();
  }

  updateResults() {
    var text = $('#content_link_search_searchtext').val();
    var type = $('.content_link_search_type .btn-group label.active input').attr('name');
    // Add a fallback because for some reason this is null in the tests but
    // not in dev?
    if(type === null || type === undefined) {
      type = 'static_content_links';
    }
    var event_ids = $('#content_link_search_event_ids').val();
    $.getJSON('/static_content_links.json', {type: type, text: text, event_ids: event_ids}, function(data) {
      var results = $('.results');
      if(data.length === 0) {
        results.html('Nothing found');
      } else {
        var html = ContentLinkSearch.result_html(data);

        results.html(html.join(''));
        var submit_button = $('.submit-choice');
        submit_button.prop('disabled', true);
        submit_button.addClass('disabled');

        $('.results .result').each(function() {
          $(this).on('click', function(e) {
            $('.result').each(function() {
              $(this).removeClass('active');
            });
            const result = $(e.currentTarget);
            result.addClass('active');
            submit_button.prop('disabled', false);
            submit_button.removeClass('disabled');
          });
        });
      }
    });
  }

  static result_html(results) {
    return results.map(function(result) {
      let text = '';
      const first = `<div class='result col-md-6'>
        <div class='updated_at pull-right'>
          ${result.updated_at}
        </div>
        <p class='title'>${result.label}</p>`;
      const last = `<div class='content_link'>${result.content_link}</div></div>`;

      if (result.long_text) {
        text = `<div class='long_text'>
           ${result.long_text.substring(0, 150)}
         </div>`;
      } else {
        text = '';
      }
      let out = first;
      out += text;
      out += last;
      return out;
    });
  }
}

// Copies the value of the selected content link to the input in the main form
class SubmitButton {
  constructor(modal, submit_button) {
    this.submit_button = submit_button;
    this.modal = modal;
    submit_button.on('click', this.setContentLink.bind(this));
  }

  setContentLink() {
    const openerButton = $('#' + this.modal[0].dataset.openerButton);
    const target_input = $('input', openerButton.parent().parent());
    const content_link = $('.result.active .content_link').text();
    target_input.val(content_link);
  }
}

export {ContentLinkSearch, SubmitButton};
