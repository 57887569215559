class ComconImportToggle {
  constructor(form) {
    this.form = form;
    this.form[0].addEventListener('ajax:success', this.onSuccess.bind(this));
    this.form[0].addEventListener('ajax:error', this.onError.bind(this));
  }

  onError(_event) {
    throw new Error('Failed to change Comcon Import Toggle state.');
  }

  onSuccess(_event) {
    // Check current state, switch to other state
    window.location.reload();
    return null;
  }
}

export default ComconImportToggle;
