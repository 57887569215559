/* eslint wrap-iife:0 */
/* eslint consistent-this:0 */
/* eslint prefer-rest-params:0 */

import Selectize from 'selectize';

// Plugin taken from: https://gist.github.com/jumika/abe412f1aa2b24ea86e6
Selectize.define('clear_selection', function() {
  var self = this;

  self.plugins.settings.dropdown_header = {
    title: 'Clear filter'
  };
  self.require( 'dropdown_header' );

  self.setup = (function() {
    var original = self.setup;

    return function () {
      original.apply(this, arguments);
      this.$dropdown.on('mousedown', '.selectize-dropdown-header', function() {
        self.setValue( '' );
        self.close();
        self.blur();

        return false;
      });
    };
  })();
});
