class RemotePaginate {
  constructor(el) {
    this.el = el;
    const target = this.el.data('target');
    $(this.el).on('click', `${target} a[href]`, this.changePage.bind(this));
  }

  changePage(e) {
    e.preventDefault();
    const link = $(e.currentTarget);
    this.el.load(link.attr("href"));
  }
}

export default RemotePaginate;
