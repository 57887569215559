// Handles the javascript form logic for the General Settings
class AppConfig {
  constructor(form) {
    this.form = form;
    this.registerCallbacks();
  }

  registerCallbacks() {
    const checkboxesAppAccess = $('#app_config_app_access_invitation_only, #app_config_app_access_with_registration_only, #app_config_app_access_open_for_everybody');
    checkboxesAppAccess.on('change', this.handleInvitationOnly.bind(this));
    return this.handleInvitationOnly();
  }

  handleInvitationOnly() {
    const invitation_only_checked = $('#app_config_app_access_invitation_only').is(":checked");
    if (invitation_only_checked) {
      return this.toggleNetworking({
        enabled: true
      });
    } else {
      return this.toggleNetworking({
        enabled: false
      });
    }
  }

  toggleNetworking(opts) {
    const networking_options = $('.networking_options');
    networking_options.prop('disabled', opts.enabled);
    networking_options.toggleClass('text-muted', opts.enabled);

    if (opts.enabled) {
      const invitation_only_networking = $('#app_config_networking_closed');
      invitation_only_networking.prop('checked', true);
      const open_networking = $('#app_config_networking_open');
      open_networking.prop('checked', false);
    }
  }
}

export default AppConfig;
