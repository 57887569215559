import 'jqtree/tree.jquery.js';
import BasicTooltip from './basic_tooltip';
import MenuValidator from './menu_validator';
import {map} from 'underscore';

class MenuConfigurator {
  constructor(bulk_actions) {
    this.bulk_actions = bulk_actions;
    this.tree_node = $('.jqtree');
  }

  init() {
    this.tree_node.tree({
      dataUrl: $('.jqtree').attr('id') + '.json',
      autoOpen: true,
      dragAndDrop: true,
      autoEscape: false,
      onCreateLi: this.onCreateLiHandler.bind(this),
      onCanMoveTo: this.moveToHandler.bind(this)
    });

    this.tree_node.bind('tree.move', function(event) {
      event.preventDefault();
      event.move_info.do_move();
      $.ajax({
        url: $(this).attr('id'),
        data: {tree: $(this).tree('toJson')},
        type: 'PUT'
      });
    });

    this.tree_node.bind('tree.init', function() {
      const menu_validator = new MenuValidator();
      menu_validator.validate();
      this.add_error_tooltips();
    }.bind(this));

    this.tree_node.on('tree.click', this.handleClick.bind(this));
  }

  update_selection(clicked_node) {
    if (this.tree_node.tree('isNodeSelected', clicked_node)) {
      this.tree_node.tree('removeFromSelection', clicked_node);

      clicked_node.children.forEach(function(child_node) {
        this.tree_node.tree('removeFromSelection', child_node);
      }, this);
    }
    else {
      this.tree_node.tree('addToSelection', clicked_node);

      clicked_node.children.forEach(function(child_node) {
        this.tree_node.tree('addToSelection', child_node);
      }, this);
    }
  }

  handleClick(event) {
    // Disable jqtree single selection
    event.preventDefault();

    this.update_selection(event.node);

    var selected_menu_item_ids = map($(".jqtree-selected > .jqtree-element[data-entry-type='items']"), function(element) {
      return element.getAttribute('data-entry-id');
    });
    var selected_menu_section_ids = map($(".jqtree-selected > .jqtree-element[data-entry-type='sections']"), function(element) {
      return element.getAttribute('data-entry-id');
    });

    var data = {
      // Needs to be a slash, because in Rails, these are namespaced classes.
      'menu/items': selected_menu_item_ids,
      'menu/sections': selected_menu_section_ids
    };

    this.bulk_actions.forEach((action) => {
      action.updateForm(data);
    });
  }

  human_type(type) {
    if(type === 'sections') {
      return 'Section';
    }
    if(type === 'items') {
      return 'Item';
    }
    throw new TypeError(`unsupported menu item type: ${type}`);
  }

  warning_icon(node) {
    const html_id = `${node.entry_type}_${node.entry_id}`;
    if(node.location_valid === false) {
      return `<span class='item_broken' aria-describedby='warning-tooltip_${html_id}'><i class='fa fa-exclamation-circle'></i></span>
      <div id='warning-tooltip_${html_id}' class='warning-tooltip tooltip' role='tooltip'>This Menu Item links to a non-existing entity in the CMS.</div>`;
    }
    return '';
  }

  edit_button(node) {
    const html_id = `${node.entry_type}_${node.entry_id}`;
    return `<a id='edit_${html_id}' href='${node.entry_type}/${node.entry_id}/edit'><i class='fa fa-edit'></i></a>`;
  }

  delete_button(node) {
    return `<a href='${node.entry_type}/${node.entry_id}' data-confirm='Are you sure you wish to delete this ${this.human_type(node.entry_type)}?' data-method='delete' data-remote='true'><i class='fa fa-trash-alt'></i></a>`;
  }

  moveToHandler(moved_node, target_node, position) {
    if(moved_node.entry_type === 'items' && target_node.entry_type === 'sections') {
      return true;
    }
    if(moved_node.entry_type === 'items' && moved_node.name === 'Home' && moved_node.location.match('home_screen')) {
      return false;
    }
    if(position === 'inside') {
      return false;
    }
    if(moved_node.entry_type === 'items') {
      return true;
    }
    if(target_node.parent.parent !== null) {
      if(moved_node.entry_type === 'sections') {
        return false;
      }
    }
    return true;
  }

  onCreateLiHandler(node, li) {
    if(!node.private || $("#current_cms_user_is_admin").val() === 'true') {
      li.find('.jqtree-element').append(`<div class='buttons'>${this.warning_icon(node)}${this.edit_button(node)}${this.delete_button(node)}</div>`);
    }

    li.find('.jqtree-element').addClass(node.entry_type);
    li.find('.jqtree-element').attr('data-entry-id', node.entry_id);
    li.find('.jqtree-element').attr('data-entry-type', node.entry_type);
    li.find('.jqtree-element').attr('data-show', 'tooltip');
    li.find('.jqtree-element').attr('data-placement', 'bottom');
    if(node.access_groups !== undefined && node.access_groups.length > 0) {
      const text = "Access Groups: " + node.access_groups;
      li.find('.jqtree-element').attr('title', text);
    }

    if(node.entry_type === 'items' && node.include_in_json === false) {
      li.find('.jqtree-element').addClass('exclude-in-json');
    }
    if(node.icon) {
      li.find('.jqtree-element').prepend(`<img src='${node.icon}' />`);
    }
    const menu_validator = new MenuValidator();
    menu_validator.validate();
  }

  add_error_tooltips() {
    const icons = document.querySelectorAll('.item_broken');

    icons.forEach((icon) => {
      const tooltip_content = icon.parentNode.querySelector('.warning-tooltip');
      new BasicTooltip(icon, tooltip_content).init();
    });
  }
}

export default MenuConfigurator;
