import 'datatables.net';
import 'datatables.net-bs';
import $ from 'jquery';
import {extend} from 'underscore';

class DatatableTable {
  td_list(attributes) {
    // FIXME: convert to non-jquery map
    return $.map(attributes, function(attr) {
      return '<td>' + attr + '</td>';
    });
  }

  subtable_template(sub_entities) {
    let html = "<table class='table'>";
    // FIXME: convert to non-jquery map
    const table_content_arr = $.map(sub_entities, function(entity) {
      const first = "<tr data-id='" + entity.data[0] + "'>\n  " + (this.td_list(entity.attributes).join('\n')) + "\n</tr>";
      // FIXME: convert to non-jquery map
      const children_arr = $.map(entity.children, function(child) {
        return "<tr data-id='" + child.data[0] + "'>\n  <td class=\"text-center\">↳</td>\n  " + (this.td_list(child.attributes).join('\n')) + "\n</tr>";
      }.bind(this));
      return first + children_arr.join('\n');
    }.bind(this));
    html += table_content_arr.join('\n');
    html += '</table>';
    return html;
  }

  constructor(el, bulk_actions, modal_bulk_actions) {
    this.el = el;
    this.bulk_actions = bulk_actions;
    this.modal_bulk_actions = modal_bulk_actions;
    this.filter_data = {};
    this.selected_ids = {};
    this.entity_plural = this.el.data('entity-plural');
    this.table = this.el.DataTable({
      processing: true,
      serverSide: true,
      dom: 'itr<"table-footer"lp>',
      lengthMenu: [[25, 50, 100], [25, 50, 100]],
      pagingType: 'simple_numbers',
      bAutoWidth: false,
      ajax: {
        url: '',
        data: function(data) {
          return extend(data, this.filter_data);
        }.bind(this),
        error: function(xhr, error) {
          if (error === 'parsererror' && xhr.responseText.indexOf('Unauthorized (401)') >= 0) {
            document.location = '/';
          }
        }
      }
    });
    // FIXME: addEventListener
    this.table.on('click', 'tr', this.onRowClick.bind(this));
    this.table.on('click', '.show-subentities', this.toggleSubTable.bind(this));
    this.table.on('draw.dt', this.onTableDraw.bind(this));
    this.table.on('column-reorder.dt', this.onColumnReorder.bind(this));
  }

  /// We unselect everything on re-ordering
  onColumnReorder() {
    this.selected_ids = {};
    this.onSelectionChange();
  }

  onTableDraw(e, settings) {
    this.selected_ids = {};
    this.onSelectionChange();
    this.toggleControls(settings);
    if (settings.oAjaxData.search.value !== "") {
      const rowLength = this.table.rows()[0].length;
      // FIXME: Would be nice to refactor this for loop..
      for (var i, j = 0, ref = rowLength; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) { // eslint-disable-line
        this.showSubTable(this.table.row(i));
      }
    }
  }

  // Update all bulk actions with new selection state
  onSelectionChange() {
    this.bulk_actions.forEach((action) => {
      action.updateForm(this.selected_ids);
    });
    this.modal_bulk_actions.forEach((action) => {
      action.setHiddenIds(this.selected_ids);
    });
  }

  // Updates the state of the table with new filters applied
  updateFilters(data) {
    this.filter_data = data;
    return this.table.ajax.reload();
  }

  isLinkOrButton(t) {
    return t.parent().hasClass('btn') || t.hasClass('btn') || t.attr('href') !== undefined;
  }

  onRowClick(e) {
    if (this.isLinkOrButton($(e.target))) {
      return;
    }
    const data = this.table.row(e.currentTarget).data();
    if (data === undefined) {
      return;
    }
    const id = data[0];
    this.toggleSelection($(e.currentTarget), id);
  }

  toggleSelection(tr, id) {
    $(tr).toggleClass('selected');
    if ($(tr).hasClass('selected')) {
      if (this.selected_ids[this.entity_plural] === undefined) {
        this.selected_ids[this.entity_plural] = [];
      }
      this.selected_ids[this.entity_plural].push(id);
      this.showSubTableAndSelectAll(this.table.row(tr));
    } else {
      const id_idx = this.selected_ids[this.entity_plural].indexOf(id);
      if (id_idx !== -1) {
        this.selected_ids[this.entity_plural].splice(id_idx, 1);
      }
    }
    this.onSelectionChange();
  }

  toggleSubTable(e) {
    e.preventDefault();
    const tr = $(e.currentTarget).closest('tr');
    const row = this.table.row(tr);
    if (row.child.isShown()) {
      row.child().find('tr.selected').each(function(idx, item) {
        this.toggleSelection(item, $(item).data('id'));
      }.bind(this));
      row.child.hide();
      tr.removeClass('shown');
    } else {
      this.showSubTable(row);
    }
    this.onSelectionChange();
  }

  generateSubTable(sub_entities) {
    if (!((sub_entities instanceof Array) && sub_entities.length > 0)) {
      return '';
    }
    return this.subtable_template(sub_entities);
  }

  showSubTable(row) {
    const data = row.data()[1];
    const rowNode = row.node();
    const subTable = this.generateSubTable(data);
    if (subTable) {
      row.child(subTable).show();
      $(rowNode).addClass('shown');
      row.child().on('click', 'tr', function(e) {
        const id = $(e.currentTarget).data('id');
        this.toggleSelection($(e.currentTarget), id);
      }.bind(this));
    }
  }

  showSubTableAndSelectAll(row) {
    if (!row.data()) {
      return;
    }
    this.showSubTable(row);
    const ref = row.child();
    (ref !== undefined && ref !== null) ? ref.find('table tr').each(function(idx, tr) {
      this.toggleSelection(tr, $(tr).data('id'));
    }.bind(this)) : null;
  }

  toggleControls(settings) {
    /* eslint-disable no-underscore-dangle */
    const collection_total = settings._iRecordsDisplay;
    $('#' + this.entity_plural + '-table_paginate').toggle(collection_total > settings._iDisplayLength);
    $('#' + this.entity_plural + '-table_length').toggle(collection_total > 25);
    $('#' + this.entity_plural + '-table_length option').each(function() {
      const show_option = (parseInt($(this).val(), 10) - 25) < collection_total;
      const display_value = Math.min(parseInt($(this).val(), 10), collection_total);
      $(this).text(display_value).toggle(show_option);
    });
  }
}

export default DatatableTable;
