class Helper {
  // Throw an error if the given selector can not be found on the page
  static ensureElement(...args) {
    var el = $(...args);
    if (el.length > 0) {
      return el;
    }
    throw new Error(`Helper.ensureElement: Selector ${args} not found!`);
  }
}

export default Helper;
