class AccessGroupConfig {
  constructor(form) {
    this.form = form;
    this.registerCallbacks();
  }

  registerCallbacks() {
    this.checkboxAccessGroups = $('#app_config_personalization_enabled');
    this.checkboxAccessGroups.on('change', this.handleCheckboxAccessGroups.bind(this));
    return this.handleCheckboxAccessGroups();
  }

  handleCheckboxAccessGroups() {
    const access_groups_checked = this.checkboxAccessGroups.is(":checked");
    // We could just pass the result of the conditional expression to the function,
    // but this way it is much more understandable what is happening.
    if (access_groups_checked) {
      return this.togglePersonalization({
        enabled: true
      });
    } else {
      return this.togglePersonalization({
        enabled: false
      });
    }
  }

  // Enable/Disable form content according to the passed state
  togglePersonalization(opts) {
    const personalization_entities = $('.personalization_entities');
    if (opts.enabled) {
      personalization_entities.toggleClass('text-muted', false);
      return $('input[type=checkbox]', personalization_entities).prop('disabled', false);
    } else {
      personalization_entities.toggleClass('text-muted', true);
      return $('input[type=checkbox]', personalization_entities).prop('checked', false).prop('disabled', true);
    }
  }
}

export default AccessGroupConfig;
