// Provides convenience methods for manipulating our checkbox DOM
class CheckboxWrapper {
  // `form_group` needs to be a simple_form `form-group`
  constructor(form_group) {
    this.form_group = form_group;
    this.hidden_field = form_group.querySelector('input[type=hidden]');
    this.checkbox = form_group.querySelector('input[type=checkbox]');
  }

  checked() {
    return this.checkbox.checked;
  }

  setChecked(val) {
    this.checkbox.checked = val;
  }

  setHiddenValue(val) {
    this.hidden_field.value = val;
  }

  // 'Disables' the checkbox but still allows the value to be submitted
  disable() {
    this.checkbox.disabled = true;
  }

  enable() {
    this.checkbox.disabled = false;
  }
}

export default CheckboxWrapper;
