class TaskPanel {
  constructor(panel, dependencies) {
    this.panel = panel;
    this.panels_to_collapse = dependencies.to_collapse;
    if (panel !== null) {
      this.collapser = panel.querySelector('details');
    }
  }

  invoke() {
    this.show_panel();
    this.expand_panel();
    this.collapse_dependent_panels();
  }

  is_collapsed() {
    !this.collapser.hasAttribute('open');
  }

  expand_panel() {
    if (this.is_collapsed) {
      this.collapser.setAttribute('open', '');
    }
  }

  collapse_panel() {
    if (!this.is_collapsed) {
      this.collapser.removeAttribute('open');
    }
  }

  // Fade-in a previously hidden panel
  show_panel() {
    $(this.panel).show(400);
  }

  collapse_dependent_panels() {
    if (this.panels_to_collapse === undefined) {
      this.panels_to_collapse = [];
    }

    this.panels_to_collapse.forEach(function(panel) {
      panel.querySelector('details').removeAttribute('open');
    });
  }
}

export default TaskPanel;
