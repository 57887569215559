import H from './helpers';

// Updates the AccessGroup assignment form buttons.
//
// Every time a selection is made in a listing, the 'updateForm'
// method is called. If the selection count is greater than 0, the
// form buttons to assign access groups are enabled, otherwise disabled.
class BulkAction {
  constructor(el){
    this.el = el;
    this.buttons = H.ensureElement('button, input[type=submit]', this.el);
    this.updateForm = this.updateForm.bind(this);
    this.toggleEnabled = this.toggleEnabled.bind(this);
    this.target = H.ensureElement(this.el.data('target'));
    this.toggleEnabled();
  }

  // set the value of the hidden input to the selected ids
  updateForm(data) {
    for(var key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const hidden_input = H.ensureElement(`input[name='${key}_ids']`, this.el);
        const val = data[key];
        hidden_input.val(val);
      }
    }
    this.toggleEnabled(data);
  }

  toggleEnabled(data) {
    this.buttons.unbind('click');
    const is_enabled = data !== undefined && Object.values(data).flat().length > 0;
    this.buttons.toggleClass('disabled', !is_enabled);
    this.buttons.attr('disabled', !is_enabled);
    this.buttons.on('click', () => is_enabled);
  }
}

export default BulkAction;
