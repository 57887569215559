class PromotionPopup {
  constructor(el) {
    this.path = el.data('promotion');
    el.on('click', this.openPopup.bind(this));
    this.template = $('#modal_template').text();
  }

  openPopup(e) {
    e.preventDefault();
    const popup = $(this.template).appendTo('body');
    popup.modal('show');
    $('.popup-content', popup).load(this.path);
    popup.on('hidden.bs.modal', popup.remove);
  }
}

export default PromotionPopup;
