// Not really sure why, but jquery needs to be re-imported in this file,
// otherwise the client side validation won't work
import $ from 'jquery';
// parsleyjs needs to be loaded after jquery
// eslint-disable-next-line sort-imports
import 'parsleyjs/dist/parsley';

class ValidateField {
  constructor(el) {
    this.el = el;
    this.parent = $(this.el.parents(".form-group").get(0));

    this.el.parsley().
      on('field:success', this.onSuccess.bind(this)).
      on('field:error', this.onError.bind(this));

    this.el.on('blur', function(e) {
      $(e.currentTarget).parsley().validate();
    });
    this.handleCKEditor(el);
  }

  onSuccess(item) { // eslint-disable-line no-unused-vars
    this.parent.removeClass("has-error");
    this.setFeedback('fa-check text-success');
  }

  onError(item) {
    this.parent.addClass("has-error");
    this.setFeedback('fa-times');
    // eslint-disable-next-line no-underscore-dangle
    item._ui.$errorsWrapper.css('top', this.messagePosition());
  }

  setFeedback(class_name) {
    var icon = `<i class='fa fa-fw ${class_name} form-control-feedback'></i>`;
    this.parent.addClass('has-feedback');
    $(".form-control-feedback", this.parent).remove();
    $(icon).appendTo(this.parent);
  }

  messagePosition() {
    var input = null;
    if(this.el.hasClass("ckeditor")) {
      input = this.el.siblings(".cke");
    }
    if(this.el.hasClass("selectized")) {
      input = this.el.siblings(".selectize-control");
    }
    if(input === null || input === undefined) {
      input = this.el;
    }
    input.outerHeight() + 5;
  }

  handleCKEditor(el) {
    if(!el.hasClass("ckeditor")) {
      return;
    }

    var ck_instance = CKEDITOR.instances[el.attr('id')];

    if(!ck_instance) {
      return;
    }
    ck_instance.on('blur', function() {
      el.trigger('blur');
    });
    ck_instance.on('contentDom', function() {
      ck_instance.document.on('keyup', function() {
        ck_instance.updateElement();
        el.trigger('keyup');
      });
    });
  }
}

// Validates forms using `parsley.js`
class ValidateForm {
  constructor(el) {
    this.el = $(el);
    this.excluded_fields = [
      'input[type=button]', 'input[type=submit]',
      'input[type=reset]', 'input[type=hidden]',
      'input[type=checkbox].optional',
      'input:not([name])', 'input[type=radio]'
    ].join(", ");

    this.initParsley();
    this.el.on('cocoon:after-insert', function() {
      this.el.parsley().destroy();
      this.initParsley();
    });
  }

  initParsley() {
    this.el.parsley({excluded: this.excluded_fields});
    $("[data-parsley-validated=true]", this.el).each(function() {
      // console.log("foo: " + foo);
      // console.log(this);
      // console.log(inst);
      new ValidateField($(this));
    });
  }
}

export default ValidateForm;
