import Sortable from 'sortablejs/modular/sortable.core.esm.js';

class RemoteSorting {
  constructor(el){
    // this.onUpdate = this.onUpdate.bind(this);
    this.path = el.getAttribute('data-update-path');
    var sortable = Sortable.create(el);
    sortable.option(
      'onUpdate', () => {
        const sorted = sortable.toArray();
        $.post(this.path, {'items': sorted});
      }
    );
  }
}

export default RemoteSorting;
