class ModalBulkAction {
  constructor(el) {
    this.el = el;
    this.target = $(this.el.data('target'));
    this.form = $("form", this.el);
    this.links = $("[data-toggle=bulk-modal][data-target='#" + (this.el.attr('id')) + "']");
    if (this.form.data("remote") === true) {
      this.form[0].addEventListener("ajax:success", this.onRemoteSuccess.bind(this));
      this.form[0].addEventListener("ajax:error", this.onRemoteError.bind(this));
    }
  }

  setHiddenIds(data) {
    for (var key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const hidden_input = $("input[name=" + key + "_ids]", this.form);
        const val = data[key];
        hidden_input.val(val);
      }
    }
    const is_enabled = data !== undefined && Object.values(data).flat().length > 0;
    this.links.toggleClass('disabled', !is_enabled);
  }

  hideOpenModals() {
    this.el.modal('hide');
    const ref = this.error_modal;
    if(ref !== null && ref !== undefined) {
      ref.modal('hide');
    }
  }

  onRemoteSuccess(_event) {
    this.hideOpenModals();
    this.target.DataTable().ajax.reload();
  }

  onRemoteError(_event) {
    const xhr = event.detail[2];
    this.hideOpenModals();
    const error_content = xhr.responseText;
    this.error_modal = $(error_content).appendTo("body").modal();
    this.error_modal.on('hide.bs.modal', (function(_this) {
      return function() {
        return _this.error_modal.remove();
      };
    }(this))).modal('show');
    return $("form", this.error_modal).on("ajax:success", this.onRemoteSuccess).on("ajax:error", this.onRemoteError);
  }
}

export default ModalBulkAction;
