import CheckboxWrapper from './checkbox_wrapper';

// Apple's review guidelines require that Apps provide a 'Sign up with Apple'
// option under specific circumstances. This class makes sure that we have it
// enabled when needed.
//
// NOTE: When you update the logic here, be sure to update it in the Admin: lib/ios/appple_signup.rb
class NetworkingSignups {
  constructor() {
    this.registerCallbacks();
  }

  registerCallbacks() {
    // When any of these checkboxes are toggled, we want to trigger our code.
    const checkboxes = document.querySelectorAll('#app_config_networking_email_registration, #app_config_networking_linkedin_registration, #app_config_networking_facebook_registration, #app_config_networking_esanum_registration');

    if (checkboxes.length) {
      this.ensureAppleSignupEnabled();
    }

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', this.ensureAppleSignupEnabled.bind(this));
    });
  }

  ensureAppleSignupEnabled() {
    const linkedin_input = document.getElementById('app_config_networking_linkedin_registration');
    const facebook_input = document.getElementById('app_config_networking_facebook_registration');
    const esanum_input = document.getElementById('app_config_networking_esanum_registration');
    const email_input = document.getElementById('app_config_networking_email_registration');
    const apple_input = new CheckboxWrapper(document.querySelector('.form-group.app_config_networking_apple_registration'));

    const linkedin_enabled = linkedin_input.checked;
    const facebook_enabled = facebook_input.checked;
    const esanum_enabled = esanum_input.checked;
    const email_enabled = email_input.checked;
    const apple_enabled = apple_input.checked();

    if(this.appleShouldBeEnabled(linkedin_enabled, facebook_enabled, esanum_enabled, email_enabled, apple_enabled)) {
      apple_input.setChecked(true);
      apple_input.setHiddenValue('1');

      apple_input.disable();
    } else {
      apple_input.enable();
    }
  }

  // NOTE: When you update the logic here, be sure to update it in the Admin: lib/ios/appple_signup.rb
  // eslint-disable-next-line max-params
  appleShouldBeEnabled(linkedin_enabled, facebook_enabled, esanum_enabled, email_enabled, apple_enabled) {
    if(typeof linkedin_enabled !== 'boolean') throw new Error('non-boolean value provided for linkedin_enabled');
    if(typeof facebook_enabled !== 'boolean') throw new Error('non-boolean value provided for facebook_enabled');
    if(typeof esanum_enabled !== 'boolean') throw new Error('non-boolean value provided for esanum_enabled');
    if(typeof email_enabled !== 'boolean') throw new Error('non-boolean value provided for email_enabled');
    if(typeof apple_enabled !== 'boolean') throw new Error('non-boolean value provided for apple_enabled');

    const esanum_and_email_enabled = esanum_enabled && email_enabled;
    if(facebook_enabled || linkedin_enabled || esanum_and_email_enabled) {
      return true;
    }
    return false;
  }
}

export default NetworkingSignups;
