import {extend} from 'underscore';

// Used to respond to filter changes on non-datatable index pages
class RemoteRefresh {
  constructor(el){
    this.el = el;
    this.params = {};
  }

  updateFilters(params){
    var filter_params = params;
    if (filter_params === null) {
      filter_params = {};
    }
    this.params = extend(this.params, filter_params);
    const path = `${window.location.pathname}?${$.param(this.params)}`;
    return this.el.load(path);
  }
}

export default RemoteRefresh;
