// Used to make a group of checkboxes behave like radio boxes
class RadioCheckbox {
  constructor(el){
    this.onChange = this.onChange.bind(this);
    this.el = el;
    const id = this.el.attr('id');
    this.inputs = $(`input[data-radio-for='${id}']`);
    this.inputs.on('click', this.onChange);
  }

  onChange(e){
    const selection = $(e.currentTarget);
    this.inputs.not(selection).prop('checked', false);
    if (selection.is(':checked')) {
      return this.el.val(selection.data('radio-value'));
    }
    return this.el.val(this.el.data('default'));
  }
}

export default RadioCheckbox;
