import 'datatables.net';
import $ from 'jquery';
import {throttle} from 'underscore';

class DatatableSearch {
  constructor(el, datatable_el) {
    this.el = el;
    this.target = $(datatable_el);
    const on_key = throttle(this.sendQuery, 200);
    this.el.on("keyup", on_key.bind(this));
  }

  sendQuery() {
    if(!this.target.hasClass("dataTable")) {
      return;
    }
    this.datatable = this.target.DataTable();
    this.datatable.search(this.el.val()).draw();
  }
}

export default DatatableSearch;
