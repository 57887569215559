import { createPopper } from '@popperjs/core';

// A tooltip that's toggled on hover/focus
class BasicTooltip {
  constructor(target, tooltip, options) {
    this.target = target;
    this.tooltip = tooltip;
    this.options = options;
    this.popperInstance = createPopper(target, tooltip, options);
  }

  init() {
    const showEvents = ['mouseenter', 'focus'];
    const hideEvents = ['mouseleave', 'blur'];

    showEvents.forEach((event) => {
      this.target.addEventListener(event, this.show.bind(this));
    });

    hideEvents.forEach((event) => {
      this.target.addEventListener(event, this.hide.bind(this));
    });
  }

  show() {
    this.tooltip.setAttribute('data-show', '');

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popperInstance.update();
  }

  hide() {
    this.tooltip.removeAttribute('data-show');
  }
}

export default BasicTooltip;
